@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body {
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  background-color: #fafafa;
  margin: 0;
  font-size: 16px;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: Roboto, sans-serif;
}

@font-face {
  font-family: 'Roboto';
  src: url('/font/Roboto-Regular.ttf');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url('/font/Roboto-Medium.ttf');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src: url('/font/Roboto-Bold.ttf');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: textfield;
}


/* Upload */
.TMRWDAO-upload {
  .TMRWDAO-upload-button {
    &:active {
      @apply border border-dashed;
    }
  }
}

.TMRWDAO-table-container .TMRWDAO-select-single {
  @apply h-[32px];
}

.TMRWDAO-select-item-option-disabled {
  @apply !text-Neutral-Disable-Text
}

.hash-link-color>span {
  @apply !text-inherit;
}
.TMRWDAO-message .TMRWDAO-message-notice-wrapper .TMRWDAO-message-custom-content>.anticon {
  vertical-align: -0.125em;
}
.TMRWDAO-table-wrapper td.TMRWDAO-table-column-sort {
  @apply bg-white;
}
.TMRWDAO-table-wrapper .TMRWDAO-table-column-sorters {
  @apply justify-start;
  
}
.TMRWDAO-table-wrapper .TMRWDAO-table-column-title {
  @apply flex-grow-0;
}
.dao-tx-hash {
  &>span {
    @apply truncate max-w-[120px];
  }
}
.TMRWDAO-pagination-container .TMRWDAO-select-selector {
  @apply !border-Neutral-Disable-Text;
}
.antExplorer-tabs-nav,
.TMRWDAO-tabs-nav {
  overflow: hidden!important;
}
.TMRWDAO-members-hash-address {
  @apply flex justify-between w-full;
  .TMRWDAO-icon {
    @apply text-[20px];
  }
}
.TMRWDAO-table-column-sorter-up.active,
.TMRWDAO-table-column-sorter-down.active {
  @apply !text-neutralTitle;
}
.TMRWDAO-table-container {
  .TMRWDAO-table-thead >tr>th {
    @apply text-Neutral-Secondary-Text;
  }
  tbody {
    @apply text-neutralPrimaryText;
  }
}
.TMRWDAO-typography {
  font-family: Roboto, sans-serif !important;
}
.table-padding-large {
  .TMRWDAO-table-thead>tr>th {
    @apply pl-[24px];
  }
  .TMRWDAO-table-tbody>tr>td {
    @apply pl-[24px];
  }
}
.clear-table-padding {
  .TMRWDAO-table-thead>tr>th {
    @apply pr-0;
  }
  .TMRWDAO-table-tbody>tr>td {
    @apply pr-0;
  }
}
.normal-table {
  .TMRWDAO-table-thead>tr>th {
     @apply text-[14px] leading-[20px] font-medium text-Neutral-Secondary-Text py-[14px]; 
  }
  .TMRWDAO-table-tbody>tr>td, .TMRWDAO-hash-address > span{
   @apply text-[12px] leading-[20px] font-normal text-neutralTitle; 
  }
 }
 .table-header-normal {
  .TMRWDAO-table-thead>tr>th {
    @apply text-[14px] leading-[20px] font-medium text-Neutral-Secondary-Text py-[14px]; 
 }
 .TMRWDAO-table-column-sorter {
  @apply h-[20px];
 }
 }
 .table-td-sm {
  .TMRWDAO-table-tbody>tr>td, .TMRWDAO-hash-address > span{
   @apply text-[14px] leading-[22px] font-normal; 
  }
 }
.full-table {
  .TMRWDAO-table-thead>tr>th:first-child {
    @apply lg:pl-[32px] pl-[16px];
 }
 .TMRWDAO-table-thead>tr>th:last-child {
    @apply lg:pr-[32px] pr-[16px];
 }
 .TMRWDAO-table-tbody>tr>td:first-child {
    @apply lg:pl-[32px] pl-[16px];
 }
 .TMRWDAO-table-tbody>tr>td:last-child {
    @apply lg:pr-[32px] pr-[16px];
 }
}
.full-table-wrap {
  .TMRWDAO-pagination-container {
    @apply lg:px-8 px-4;
  }
}
.TMRWDAO-table-expanded-row-fixed {
  @apply !w-full !m-0;
}
.TMRWDAO-form-item .TMRWDAO-form-item-explain-error {
  @apply mt-[8px];
}
/* .TMRWDAO-form-item-margin-offset {
  @apply !mb-0;
}
.TMRWDAO-form-item {

  .TMRWDAO-form-item-control {
    .TMRWDAO-form-item-explain {
      @apply text-[16px] leading-[24px] h-[24px] mt-[8px];
      .TMRWDAO-form-item-explain-error {
        @apply text-[#F53F3F];
      }
    }
  }
} */